'use client'

import React, { useEffect } from 'react'

const ActionCableContext = React.createContext()

function ActionCableProvider({ children }) {
  const CableApp = {}

  useEffect(() => {
    const actionCableUrl = process.env.NEXT_PUBLIC_API_WS_URL
    if (typeof window !== 'undefined') {
      var actioncable = require('actioncable')
    }
    CableApp.cable = actioncable.createConsumer(actionCableUrl)
  }, [])

  if (typeof window !== 'undefined') {
    return (
      <ActionCableContext.Provider value={CableApp}>
        {children}
      </ActionCableContext.Provider>
    )
  }
  return <>{children}</>
}

export { ActionCableContext, ActionCableProvider }
